import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import MlIcon from "../components/mlIcon"
import BlogPostSummary from "../components/blogPostSummary"

export default () => {
  const {
    file: {
      childMarkdownRemark: { frontmatter },
    },
    desktop,
    recentPosts: { nodes: recentPosts },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/home.md" }) {
        childMarkdownRemark {
          frontmatter {
            title
            subtitle
            meta {
              title
              description
            }
          }
        }
      }
      desktop: file(relativePath: { eq: "peaceful-mind.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recentPosts: allFile(
        filter: { relativeDirectory: { eq: "blog-posts" } }
        sort: {
          fields: [childMarkdownRemark___frontmatter___date]
          order: DESC
        }
        limit: 3
      ) {
        nodes {
          name
          ...BlogPostSummary_post
        }
      }
    }
  `)

  return (
    <Layout meta={frontmatter.meta}>
      <section className="hero-section">
        <div className="grid-2-col-hero">
          <div className="hero-img-wrapper flex flex-end-bottom">
            <Img
              className="hero-img img-responsive"
              fluid={desktop.childImageSharp.fluid}
              alt="still mind of a woman with a warm sunset on her mind"
            />
          </div>
          <div className="hero-text-wrapper flex flex-ac">
            <div className="hero-text">
              <div>
                <MlIcon />
                <h1 className="animated fadeInUp fast">{frontmatter.title}</h1>
                <p className="lead animated fadeInUp">{frontmatter.subtitle}</p>
                <div className="animated fadeInUp delay-2s mt-2">
                  <Link className="btn btn-green-solid" to="/about/">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lg-pad">
        <div className="container">
          <div className="row">
            <div className="well with-bg">
              <h2>Welcome to Creative Thinking Institute</h2>
              <p className="lead">
                Through a multi-faceted health program, CTI helps people
                navigate life’s competing demands. We use Medtech to create
                products for medical and allied health professionals that are
                designed to{" "}
                <strong>
                  reinvent employee assistance programs and improve human
                  performance
                </strong>
                .
              </p>
              <p className="lead">
                Each day, we are guided by our core values of: we celebrate
                difference, we do what we say we will, we reimagine what is
                possible, and we always collaborate. Our innovative approaches
                to business consider the impact our products and processes have
                on the health and well-being of our customers, our users and our
                planet.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="lg-pad">
        <div className="container">
          <div className="row">
            <h3>Recent posts</h3>
          </div>
          <div className="grid-3-col">
            {recentPosts.map(post => (
              <BlogPostSummary key={post.name} post={post} />
            ))}
          </div>
          <div className="row flex flex-jc mt-2">
            <Link className="btn btn-green-solid" to="/blog">
              View all posts
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}
